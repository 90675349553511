import resume from '../assets/pdf/resume.pdf'
import headshot from '../assets/jfif/headshot.jfif'

export const headerData = {
    name: 'Cordell Rhoads',
    title: "Senior Software Engineer",
    desciption:"B.S. Software Engineering | University of Nebraska-Lincoln",
    image: headshot,
    resumePdf: resume
}
