import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeContextProvider from './contexts/ThemeContext'


import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <ThemeContextProvider>
      <App />
    </ThemeContextProvider>
  </HelmetProvider>,
);


reportWebVitals();
