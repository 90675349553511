import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles';
import './Footer.css'
import { ThemeContext } from '../../contexts/ThemeContext'
import { headerData } from '../../data/headerData'

function Footer() {

    const shortname = (name) => {
        if(name.length > 10) {
            return name.split(" ")[0]
        } else {
            return name
        }
    }

    const { theme }  = useContext(ThemeContext)

    const useStyles = makeStyles((t) => ({
        hostBtn: {
            color: theme.palette.primary.main,
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: '200px',
            fontWeight: '500',
            fontFamily: 'var(--primaryFont)',
            transition: '100ms ease-out',
            '&:hover': {
                color: theme.palette.custom.primary80,
                border: `3px solid ${theme.tertiary}`,
            },
            [t.breakpoints.down('sm')]: {
                width: '180px',
            },
        },
    }));

    const classes = useStyles();

    return (
        <div className="footer" style={{backgroundColor: theme.secondary}}>
            <p style={{color: theme.palette.secondary.main}}>
                Hosted By  <a className={classes.hostBtn} href="https://kodysalak.com/" target="_blank" rel="noopener noreferrer">Kody Salak</a>
                <span style={{color: theme.primary, margin: '0 0.5rem -1rem 0.5rem'}}>
                    🏈
                </span>
                Go Huskers!
            </p>
        </div>
    )
}

export default Footer

