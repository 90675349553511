import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import nsc from '../assets/png/nsc.PNG'
import pitivi from '../assets/png/pitivi.png'
import placeholder from '../assets/png/placeholder.png'
import cornhacks from '../assets/png/cornhacks.png'
import projectconnect from '../assets/jfif/projectconnect.jfif'
import alaska from '../assets/jpeg/alaska.JPEG'


export const extracurricularData = [
    {
        id: 1,
        projectName: 'CornHacks 🌽💻',
        projectDesc: 'CornHacks is an annual hackathon that is hosted by students from the University of Nebraska-Lincoln. I volunteered to organize the event for 4 years, eventually having the privilege to be the President of the organization.',
        demo: 'https://unlcornhacks.com/',
        image: cornhacks
    },
    {
        id: 2,
        projectName: 'Travel 🧳🌎',
        projectDesc: 'Seeing the world is a must for many people. While I do not get to travel a lot, I still enjoy the occasional vacation. Most recently, I made a trip to Southern Alaska.',
        demo: 'https://photos.app.goo.gl/x7cr9Lk5u61V1QVg8',
        image: alaska
    },
    {
        id: 3,
        projectName: 'Volunteering 💕🙏',
        projectDesc: 'I enjoy giving back to the community when I can. Connected to this tile is a fun video of my work team putting together hygeine kits for Project Connect.',
        demo: 'https://www.1011now.com/video/2024/10/10/fiserv-employees-create-500-hygiene-kits-ahead-project-connect-lincoln-event/',
        image: projectconnect
    },
    // {
    //     id: 4,
    //     projectName: 'Reliance Digital Clone - Backend Integration',
    //     projectDesc: 'This is the clone of reliancedigital.in. Features I worked on the Product Catalogue pages and also on some minor parts on other pages as well',
    //     tags: ['EJS', 'Express', 'MongoDB', 'Node.js'],
    //     code: 'https://github.com/sreerag-rajan/Reliance-Digital-Clone-U4-',
    //     demo: 'https://reliance-digital-clone.herokuapp.com/',
    //     image: "https://miro.medium.com/max/875/1*qRNfRbuHCDC1ct0chLZoWA.png"
    // },
    // {
    //     id: 5,
    //     projectName: 'Reliance Digital Clone - Frontend',
    //     projectDesc: 'This project is the clone of Reliance Digital built as a part of a project week. It simulates the functionality of an e-commerce website from landing page to checkout. My contribution was the product catalog page arriving from the navbar, as well as leading the team.',
    //     tags: ['HTML', 'CSS', 'Javascript'],
    //     code: 'https://github.com/iamrituyadav/Reliance_digital',
    //     demo: 'https://reliance-digital-clone.herokuapp.com/',
    //     image: "https://miro.medium.com/max/875/1*qRNfRbuHCDC1ct0chLZoWA.png"
    // },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/