import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade } from 'react-awesome-reveal';
import { FaPlay } from 'react-icons/fa';

import { ThemeContext } from '../../contexts/ThemeContext';

import { AiOutlineFolder } from "react-icons/ai";

import './Achievement.css'

function AchievementCard({id, title, details, date, field, image, demo}) {

    const { theme } = useContext(ThemeContext);

    // const useStyles = makeStyles((t) => ({
        // achievementCard : {
        //     backgroundColor:theme.palette.custom.primary30,
        //     "&:hover": {
        //         backgroundColor:theme.palette.custom.primary50,
        //     },
        // },
    // }));

    const useStyles = makeStyles((t) => ({
        iconBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: 50,
            border: `2px solid ${theme.tertiary}`,
            color: theme.palette.secondary.main,
            transition: 'all 0.2s',
            '&:hover': {
                backgroundColor: theme.secondary,
                color: theme.palette.primary.main,
                transform: 'scale(1.1)',
                border: `2px solid ${theme.secondary}`,
            },
        },
        icon: {
            fontSize: '1.1rem',
            transition: 'all 0.2s',
            '&:hover': {},
        },
        achievementCard : {
            width:'30rem',
            backgroundColor:theme.palette.custom.primary30,
            "&:hover": {
                backgroundColor:theme.palette.custom.primary50,
            },
        },
        iconBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: 50,
            border: `2px solid ${theme.tertiary}`,
            color: theme.palette.secondary.main,
            transition: 'all 0.2s',
            '&:hover': {
                backgroundColor: theme.secondary,
                color: theme.palette.custom.secondary70,
                transform: 'scale(1.1)',
                border: `2px solid ${theme.secondary}`,
            },
        },
        icon: {
            fontSize: '1.1rem',
            transition: 'all 0.2s',
            '&:hover': {},
        },
    }));

    const classes = useStyles();

    return (
        <Fade bottom>
           <div key={id} className={`achievement-card ${classes.achievementCard}`}>
               <div className="achievecard-content">
                    <div className="achievecard-details1">
                        <h2 style={{color: theme.palette.custom.tertiary80}}>{title}</h2>
                        <p style={{color: theme.palette.custom.tertiary80}}>{details}</p>
                    </div>
                    <div className="achievecard-details2" style={{color: theme.palette.custom.tertiary80}}>
                        <h5>{date}</h5> 
                    </div>
                    <div>
                        <a
                            href={demo}
                            target='_blank'
                            rel='noreferrer'
                            className={classes.iconBtn}
                            aria-labelledby={`${title
                                .replace(' ', '-')
                                .toLowerCase()} ${title
                                .replace(' ', '-')
                                .toLowerCase()}-demo`}
                        >
                            <FaPlay
                                id={`${title
                                    .replace(' ', '-')
                                    .toLowerCase()}-demo`}
                                className={classes.icon}
                                aria-label='Demo'
                            />
                        </a>
                    </div>
                </div> 
                <div className="achievecard-imgcontainer">
                    <img src={image} alt="" />
                </div>
           </div>
        </Fade>
        
    )
}

export default AchievementCard
