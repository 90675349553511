export const educationData = [
    {
        id: 1,
        institution: 'University of Nebraska-Lincoln, College of Engineering',
        course: 'Bachelor\'s of Science, Software Engineering',
        startYear: '2018',
        endYear: '2022'
    },
    // {
    //     id: 2,
    //     institution: 'Delhi University',
    //     course: 'M.A. History',
    //     startYear: '2019',
    //     endYear: '2021'
    // },
    // {
    //     id: 3,
    //     institution: 'Masai School of Coding',
    //     course: 'Fullstack Web-Development',
    //     startYear: '2021',
    //     endYear: 'Present'
    // },
]