import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import nsc from '../assets/png/nsc.PNG'
import pitivi from '../assets/png/pitivi.png'
import placeholder from '../assets/png/placeholder.png'


export const projectsData = [
    {
        id: 1,
        projectName: 'Data Visualization',
        projectDesc: 'This project converted data collected from events into various graphs to be used by the Nebraska Sports Council, a 501 (c) (3) non-profit organization.',
        tags: ['Python', 'Pandas' ,'Numpy', 'CircleCI'],
        code: 'https://github.com/sreerag-rajan/historythroughmovies',
        demo: 'https://www.youtube.com/watch?v=OYGFFsD32kY',
        image: nsc
    },
    {
        id: 2,
        projectName: 'Pitivi',
        projectDesc: 'Contributed new feature work to an open source video editor. Created an alignment widget to allow for easy alignment in the video editor.',
        tags: ['Python', 'GStreamer'],
        code: 'https://gitlab.gnome.org/GNOME/pitivi/',
        demo: 'https://www.pitivi.org/tour/',
        image: pitivi
    },
    {
        id: 3,
        projectName: 'Digital Movie Poster',
        projectDesc: 'Utilizes AppleTV libary in unison with a movie poster API to create a dynamic poster similar to one seen outside of a movie theater.',
        tags: ['Python', 'Javascript'],
        code: 'https://github.com/cordellrhoads/movie-poster-proto',
        demo: '',
        image: placeholder
    },
    // {
    //     id: 4,
    //     projectName: 'Reliance Digital Clone - Backend Integration',
    //     projectDesc: 'This is the clone of reliancedigital.in. Features I worked on the Product Catalogue pages and also on some minor parts on other pages as well',
    //     tags: ['EJS', 'Express', 'MongoDB', 'Node.js'],
    //     code: 'https://github.com/sreerag-rajan/Reliance-Digital-Clone-U4-',
    //     demo: 'https://reliance-digital-clone.herokuapp.com/',
    //     image: "https://miro.medium.com/max/875/1*qRNfRbuHCDC1ct0chLZoWA.png"
    // },
    // {
    //     id: 5,
    //     projectName: 'Reliance Digital Clone - Frontend',
    //     projectDesc: 'This project is the clone of Reliance Digital built as a part of a project week. It simulates the functionality of an e-commerce website from landing page to checkout. My contribution was the product catalog page arriving from the navbar, as well as leading the team.',
    //     tags: ['HTML', 'CSS', 'Javascript'],
    //     code: 'https://github.com/iamrituyadav/Reliance_digital',
    //     demo: 'https://reliance-digital-clone.herokuapp.com/',
    //     image: "https://miro.medium.com/max/875/1*qRNfRbuHCDC1ct0chLZoWA.png"
    // },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/