import seventhgrade from '../assets/png/seventhgrade.PNG'

export const achievementData = {
    bio : "Random tidbits from my life",
    achievements : [
        {
            id : 1,
            title : 'Cornhacks Goes Virtual',
            details : '',
            date : 'Jan 28, 2021',
            field : 'Coding',
            image : 'https://newsroom.unl.edu/announce/files/file145513.png',
            demo : 'https://newsroom.unl.edu/announce/stories/71440'
        },
        {
            id : 2,
            title : 'Merit Pages',
            details : 'University of Nebraska-Lincoln',
            date : '2018-2022',
            field : 'Database',
            image : 'https://images.squarespace-cdn.com/content/v1/5c2e22952714e50bb80b2785/df25843b-f880-409f-a3a9-7e323bc40ca7/Merit.png?format=1500w',
            demo : 'https://meritpages.com/cordellrhoads'
        },
        {
            id : 3,
            title : 'Seventh Grade Scholar',
            details : '',
            date : 'May 26, 2013',
            field : 'Data Science',
            image : seventhgrade,
            demo : 'https://columbustelegram.com/news/local/columbus-boy-thriving-after-winning-cancer-battle/article_0546c494-9e88-5920-b66c-0f7e312c6d70.html'
        },
        {
            id : 3,
            title : 'Relay For Life Honorary Survivor',
            details : '',
            date : 'June 13, 2007',
            field : 'Machine Learning',
            image : 'https://th.bing.com/th/id/OIP.yh4fSPYLGKPctDXFHbBheQHaGr?w=196&h=180&c=7&r=0&o=5&pid=1.7',
            demo : 'https://columbustelegram.com/news/ornery-streak-helped-in-cancer-fight/article_1ddb40a1-4fc9-5d38-bdc4-a2f3296f9b0c.html'
        }
    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/