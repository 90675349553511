import React,{ useContext } from 'react';
import { makeStyles } from '@mui/styles';

import { Link } from 'react-router-dom'
import { HiArrowRight } from "react-icons/hi";
import SingleExtracurricular from './SingleExtracurricular/SingleExtracurricular';
import './Extracurricular.css'
import { extracurricularData } from '../../data/extracurricularData'

import { ThemeContext } from '../../contexts/ThemeContext';


function Extracurricular() {

    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        viewAllBtn : {
            color: theme.tertiary, 
            backgroundColor: theme.primary,
            transition: 'color 0.2s',
            "&:hover": {
                color: theme.palette.primary.contrastText, 
                backgroundColor: theme.palette.primary.main,
            }
        },
        viewArr : {
            color: theme.palette.primary.main, 
            backgroundColor: theme.palette.custom.tertiary50,
            width: '40px',
            height: '40px',
            padding: '0.5rem',
            fontSize: '1.05rem',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            "&:hover": {
                color: theme.palette.primary.contrastText, 
                backgroundColor: theme.palette.primary.main,
            }
        },
    }));

    const classes = useStyles();

    return (
        <>
            {extracurricularData.length > 0 && (
                <div className="extracurriculars" id="extracurriculars" style={{backgroundColor: theme.palette.custom.tertiary80}}>
                    <div className="extracurricular--header">
                        <h1 style={{color: theme.primary}}>Other Fun</h1>
                    </div>
                    <div className="extracurricular--body">
                        <div className="extracurricular--bodyContainer">
                            {extracurricularData.slice(0, 4).map(project => (
                                <SingleExtracurricular
                                    theme={theme}
                                    key={project.id}
                                    id={project.id}
                                    name={project.projectName}
                                    desc={project.projectDesc}
                                    tags={project.tags}
                                    code={project.code}
                                    demo={project.demo}
                                    image={project.image}
                                />
                            ))}
                        </div> 

                        {extracurricularData.length > 3 && (
                            <div className="extracurricular--viewAll">
                                <Link to="/extracurricular">
                                    <button className={classes.viewAllBtn}>
                                        View All
                                        <HiArrowRight className={classes.viewArr} />
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
        // <div id ="extracurricular"className="extracurricular" style={{backgroundColor: theme.palette.custom.tertiary80}}>
        //     <div className="extracurricularHeader">
        //         <h2 style={{color: theme.primary}}>Extracurricular</h2>
        //     </div>
        //     <div className="extracurricularContainer">
        //         <div className="skill--scroll">
        //             <Marquee 
        //                 gradient={true} 
        //                 gradientColor={theme.palette.custom.tertiary80}
        //                 speed={80} 
        //                 pauseOnHover={true}
        //                 pauseOnClick={true} 
        //                 delay={0}
        //                 play={true} 
        //                 direction="left"
        //             >
        //                 {extracurricularData.map((skill, id) => (
        //                     <div className="skill--box" key={id} style={skillBoxStyle}>
        //                         <img src={skillsImage(skill)} alt={skill} />
        //                         <h3 style={{color: theme.tertiary}}>
        //                             {skill}
        //                         </h3>
        //                     </div>
        //                 ))}
        //             </Marquee>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Extracurricular
